<template>
  <z-card width="100%" title="Reenviar documentos">
    <template #content>
      <div class="instructions-container">
        <illustration-document-scan />
        <illustration-certidao v-if="false"></illustration-certidao>

        <template v-if="hasUpload">
          <h4 class="body-text text-center px-6">
            {{ $t("reservation.scanner_instruction_title") }}
          </h4>
          <div class="divider"></div>
          <p v-if="hasUpload" class="body2 text-center">
            {{ $t("documentScanner.select_type_of_document") }}
          </p>
        </template>

        <p class="body2 text-center">
          {{ $t("documentScanner.type_your_document_number") }}
        </p>

        <z-select
          hide-details
          :style="{
            'max-width': $vuetify.breakpoint.smAndDown ? '100%' : '250px',
            width: $vuetify.breakpoint.smAndDown ? '100%' : '250px',
          }"
          :label="$t('guest.document_type')"
          :value="resend.documentTypeId"
          :items="documentTypes"
          @change="
            $emit('change', { documentTypeId: $event, documentNumber: '' })
          "
        />

        <z-input
          v-if="documentMask"
          key="document-with-mask"
          :style="{
            'max-width': $vuetify.breakpoint.smAndDown ? '100%' : '250px',
            width: $vuetify.breakpoint.smAndDown ? '100%' : '250px',
          }"
          :mask="documentMask"
          :error-messages="
            isValidDocument ? [] : [$t('validations.isValidDocument')]
          "
          :label="$t('guest.document_number')"
          :value="resend.documentNumber"
          @input="$emit('change', { documentNumber: $event })"
        />

        <z-input
          v-else
          key="document-without-mask"
          :style="{
            'max-width': $vuetify.breakpoint.smAndDown ? '100%' : '250px',
            width: $vuetify.breakpoint.smAndDown ? '100%' : '250px',
          }"
          :label="$t('guest.document_number')"
          :value="resend.documentNumber"
          @input="$emit('change', { documentNumber: $event })"
        />

        <template v-if="hasUpload">
          <p class="body2 text-center">
            {{ $t("reservation.scanner_instruction_description") }}
          </p>

          <ul>
            <li
              v-for="list in listItemsMobile"
              :key="list.text"
              class="d-flex align-center"
            >
              <z-icon class="pass-alert-icon mx-2" size="18px" color="green">
                $check_in_a_circle
              </z-icon>
              <p class="mb-0 body2">{{ list.text }}</p>
            </li>
          </ul>
        </template>
      </div>

      <div class="actions-container" style="width: 100%">
        <z-btn
          v-if="hasUpload && $vuetify.breakpoint.mdAndUp"
          width="130px"
          :text="$t('documentScanner.scanner_upload_button_upload')"
          :disabled="!isValidDocument || isLoading"
          @click="$emit('click:upload')"
        />
        <z-btn
          primary
          :is-loading="isLoading"
          :width="$vuetify.breakpoint.mdAndUp ? '130px' : '100%'"
          :text="$t('documentScanner.scanner_upload_button_take-picture')"
          :disabled="!isValidDocument"
          @click="$emit('click:continue')"
        />
        <z-btn
          v-if="hasUpload && $vuetify.breakpoint.smAndDown"
          width="100%"
          :text="$t('documentScanner.scanner_upload_button_upload')"
          :disabled="!isValidDocument || isLoading"
          @click="$emit('click:upload')"
        />
      </div>
    </template>
  </z-card>
</template>

<script>
import { computed, toRefs } from "vue"

import { DocumentMasks, DocumentTypeValues } from "@/consts"
import i18n from "@/i18n"
import {
  isValidCIE,
  isValidCPF,
  isValidDNI,
  isValidID,
  isValidPassport,
  isValidRUT,
} from "@/validators"

export default {
  props: {
    resend: {
      type: Object,
      required: true,
    },
    documentTypes: {
      type: Array,
      default: () => [],
    },
    hasUpload: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { resend } = toRefs(props)

    const listItemsMobile = computed(() => {
      return [
        { text: i18n.tc("reservation.scanner_instructions_list1") },
        { text: i18n.tc("reservation.scanner_instructions_list2") },
        { text: i18n.tc("reservation.scanner_instructions_list3") },
        { text: i18n.tc("reservation.scanner_instructions_list4") },
      ]
    })

    const isValidDocument = computed(() => {
      const documentNumber = resend.value.documentNumber

      switch (resend.value.documentTypeId) {
        case 1:
          return isValidCPF(documentNumber)
        case 2:
          return isValidCIE(documentNumber)
        case 3:
          return isValidID(documentNumber)
        case 4:
          return isValidPassport(documentNumber)
        case 5:
          return isValidRUT(documentNumber)
        case 6:
          return isValidDNI(documentNumber)
        default:
          return true
      }
    })

    const documentMask = computed(() => {
      // CPF
      if (resend.value.documentTypeId === DocumentTypeValues.CPF) {
        return DocumentMasks.CPF
      }

      return false
    })

    return { documentMask, isValidDocument, listItemsMobile }
  },
}
</script>

<style lang="scss" scoped>
ul li {
  margin-bottom: var(--core-spacing-lg);
}

h4 {
  max-width: 500px;
}

.instructions-container {
  display: grid;
  justify-items: center;
  margin-top: var(--core-spacing-lg);
  row-gap: var(--core-spacing-md);
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--black-300);
  max-width: 700px;
}

.actions-container {
  display: grid;
  row-gap: var(--core-spacing-md);
}

@media screen and (min-width: 786px) {
  .actions-container {
    grid-template-columns: min-content min-content;
    column-gap: var(--core-spacing-md);
    justify-content: center;
    margin-top: var(--core-spacing-lg);
  }
}
</style>
