<template>
  <div>
    <resend-docs
      :resend="resend"
      :document-types="defaultDocumentTypes"
      :has-upload="hasUpload"
      :is-loading="isLoading"
      @change="changeResend"
      @click:continue="onClickContinue"
      @click:upload="onClickUpload"
    />
    <document-scanner
      v-if="modals.isDocumentScanOpen"
      :opened="modals.isDocumentScanOpen"
      :front="resend.document.front"
      :back="resend.document.back"
      :is-loading="isLoading"
      :title="
        !resend.document.front
          ? $t('documentScanner.scanner_modal_titleFront')
          : $t('documentScanner.scanner_modal_titleBack')
      "
      @finish="onFinish"
      @confirm-scanned-document="onChangeDocument"
      @close="modals.isDocumentScanOpen = false"
    />
  </div>
</template>

<script>
import find from "lodash.find"
import { computed, onMounted, reactive } from "vue"
import { useRoute, useRouter } from "vue-router/composables"
import {
  useNamespacedGetters,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

import { DocumentSide } from "@/consts"

import ResendDocs from "./components/ResendDocs.vue"
import { useResendDocuments } from "./useResendDocuments"

/**
 *
 */
export default {
  components: { ResendDocs },
  setup() {
    const modals = reactive({
      isDocumentScanOpen: false,
    })
    const { defaultDocumentTypes } = useNamespacedGetters("parameters", [
      "defaultDocumentTypes",
    ])
    const { personalInfoConfig } = useNamespacedGetters("company", [
      "personalInfoConfig",
    ])
    const { resend, changeResend, isLoading, onFinish } = useResendDocuments()
    const { reservation } = useNamespacedState("reservation", ["reservation"])

    const router = useRouter()
    const route = useRoute()

    const hasUpload = computed(() => {
      return personalInfoConfig.value.documentData?.mainDocument?.rules
        ?.documentUpload
    })

    const onClickContinue = () => {
      if (hasUpload.value) {
        modals.isDocumentScanOpen = true
      } else {
        onFinish()
      }
    }

    const onClickUpload = () => {
      router.push({
        name: "document-scan-upload",
        params: route.params,
      })
    }

    const onChangeDocument = (value, side) => {
      if (side === DocumentSide.Front) {
        changeResend({ "document.front": value })
      } else if (side === DocumentSide.Back) {
        changeResend({ "document.back": value })
      }
    }

    onMounted(() => {
      const guest = find(reservation.value.guests, {
        id: route.params.guestId,
      })

      changeResend({
        guestId: guest.id,
        fnrhId: guest.fnrhId,
        documentTypeId: defaultDocumentTypes.value[0]?.value ?? "",
      })
    })

    return {
      isLoading,
      modals,
      resend,
      defaultDocumentTypes,
      hasUpload,

      changeResend,
      onFinish,
      onChangeDocument,
      onClickContinue,
      onClickUpload,
    }
  },
}
</script>
