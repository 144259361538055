var render = function render(){var _vm=this,_c=_vm._self._c;return _c('z-card',{attrs:{"width":"100%","title":"Reenviar documentos"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"instructions-container"},[_c('illustration-document-scan'),(false)?_c('illustration-certidao'):_vm._e(),(_vm.hasUpload)?[_c('h4',{staticClass:"body-text text-center px-6"},[_vm._v(" "+_vm._s(_vm.$t("reservation.scanner_instruction_title"))+" ")]),_c('div',{staticClass:"divider"}),(_vm.hasUpload)?_c('p',{staticClass:"body2 text-center"},[_vm._v(" "+_vm._s(_vm.$t("documentScanner.select_type_of_document"))+" ")]):_vm._e()]:_vm._e(),_c('p',{staticClass:"body2 text-center"},[_vm._v(" "+_vm._s(_vm.$t("documentScanner.type_your_document_number"))+" ")]),_c('z-select',{style:({
          'max-width': _vm.$vuetify.breakpoint.smAndDown ? '100%' : '250px',
          width: _vm.$vuetify.breakpoint.smAndDown ? '100%' : '250px',
        }),attrs:{"hide-details":"","label":_vm.$t('guest.document_type'),"value":_vm.resend.documentTypeId,"items":_vm.documentTypes},on:{"change":function($event){return _vm.$emit('change', { documentTypeId: $event, documentNumber: '' })}}}),(_vm.documentMask)?_c('z-input',{key:"document-with-mask",style:({
          'max-width': _vm.$vuetify.breakpoint.smAndDown ? '100%' : '250px',
          width: _vm.$vuetify.breakpoint.smAndDown ? '100%' : '250px',
        }),attrs:{"mask":_vm.documentMask,"error-messages":_vm.isValidDocument ? [] : [_vm.$t('validations.isValidDocument')],"label":_vm.$t('guest.document_number'),"value":_vm.resend.documentNumber},on:{"input":function($event){return _vm.$emit('change', { documentNumber: $event })}}}):_c('z-input',{key:"document-without-mask",style:({
          'max-width': _vm.$vuetify.breakpoint.smAndDown ? '100%' : '250px',
          width: _vm.$vuetify.breakpoint.smAndDown ? '100%' : '250px',
        }),attrs:{"label":_vm.$t('guest.document_number'),"value":_vm.resend.documentNumber},on:{"input":function($event){return _vm.$emit('change', { documentNumber: $event })}}}),(_vm.hasUpload)?[_c('p',{staticClass:"body2 text-center"},[_vm._v(" "+_vm._s(_vm.$t("reservation.scanner_instruction_description"))+" ")]),_c('ul',_vm._l((_vm.listItemsMobile),function(list){return _c('li',{key:list.text,staticClass:"d-flex align-center"},[_c('z-icon',{staticClass:"pass-alert-icon mx-2",attrs:{"size":"18px","color":"green"}},[_vm._v(" $check_in_a_circle ")]),_c('p',{staticClass:"mb-0 body2"},[_vm._v(_vm._s(list.text))])],1)}),0)]:_vm._e()],2),_c('div',{staticClass:"actions-container",staticStyle:{"width":"100%"}},[(_vm.hasUpload && _vm.$vuetify.breakpoint.mdAndUp)?_c('z-btn',{attrs:{"width":"130px","text":_vm.$t('documentScanner.scanner_upload_button_upload'),"disabled":!_vm.isValidDocument || _vm.isLoading},on:{"click":function($event){return _vm.$emit('click:upload')}}}):_vm._e(),_c('z-btn',{attrs:{"primary":"","is-loading":_vm.isLoading,"width":_vm.$vuetify.breakpoint.mdAndUp ? '130px' : '100%',"text":_vm.$t('documentScanner.scanner_upload_button_take-picture'),"disabled":!_vm.isValidDocument},on:{"click":function($event){return _vm.$emit('click:continue')}}}),(_vm.hasUpload && _vm.$vuetify.breakpoint.smAndDown)?_c('z-btn',{attrs:{"width":"100%","text":_vm.$t('documentScanner.scanner_upload_button_upload'),"disabled":!_vm.isValidDocument || _vm.isLoading},on:{"click":function($event){return _vm.$emit('click:upload')}}}):_vm._e()],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }